#services {
    padding: 80px 0 0;
    color: #fff;
   
    
  }

  .main_container{
    width: 100%;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  }
  #services .service-desc {
    margin: 10px 10px 20px;
  }
  #services h2 {
    color: #fff;
  }
  #services .section-title h2::after {
    position: absolute;
    content: "";
    background: rgba(255, 255, 255, 0.3);
    height: 4px;
    width: 60px;
    bottom: 0;
    margin-left: -30px;
    left: 50%;
  }
  #services i.fa {
    font-size: 42px;
    width: 120px;
    height: 120px;
    padding: 40px 0;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    border-radius: 50%;
    color: #fff;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
  }
  #services h3 {
    font-weight: 500;
    padding: 5px 0;
    color: #fff;
  }
  #services p {
    color: rgba(255, 255, 255, 0.75);
  }
  #services .service-desc {
    margin-bottom: 40px;
  }

/* About css */
  #about {
    padding: 100px 0;
  }
  #about h3 {
    font-size: 22px;
    margin: 0 0 20px;
  }
  #about h2 {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  #about h2::after {
    position: absolute;
    content: "";
    background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
    height: 4px;
    width: 60px;
    bottom: 0;
    left: 0;
  }
  #about .about-text li {
    margin-bottom: 6px;
    margin-left: 6px;
    list-style: none;
    padding: 0;
  }
  #about .about-text li:before {
    content: "\f00c";
    font-family: "FontAwesome";
    color: #5ca9fb;
    font-size: 11px;
    font-weight: 300;
    padding-right: 8px;
  }
  #about img {
    width: 520px;
    margin-top: 10px;
    background: #fff;
    border-right: 0;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
  }
  #about p {
    line-height: 24px;
    margin: 30px 0;
  }




  /* Testimonials */

  #testimonials {
    padding: 100px 0;
    background: #f6f6f6;
  }
  #testimonials i {
    color: #e6e6e6;
    font-size: 32px;
    margin-bottom: 20px;
  }
  .testimonial {
    position: relative;
    padding: 20px;
  }
  .testimonial-image {
    float: left;
    margin-right: 15px;
  }
  .testimonial-image,
  .testimonial-image img {
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
  .testimonial-content {
    position: relative;
    overflow: hidden;
  }
  .testimonial-content p {
    margin-bottom: 0;
    font-size: 14px;
    font-style: italic;
  }
  .testimonial-meta {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 600;
    color: #666;
  }
  /* Team Section */
  #team {
    padding: 100px 0;
  }
  #team h4 {
    margin: 5px 0;
  }
  #team .team-img {
    width: 240px;
  }
  #team .thumbnail {
    background: transparent;
    border: 0;
  }
  #team .thumbnail .caption {
    padding: 10px 0 0;
    color: #888;
  }



  /* Contact Section */
#contact {
  /* padding: 80px 0 70px;p */
  /* background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%); */
  color: rgba(255, 255, 255, 0.75);
}
#contact .section-title {
  margin-bottom: 40px;
}
#contact .section-title p {
  font-size: 16px;
}
#contact h2 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#contact .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 30px;
}
#contact h3 {
  color: #fff;
  margin-top: 80px;
  margin-bottom: 25px;
  padding-bottom: 20px;
  font-weight: 400;
}
#contact form {
  padding-top: 20px;
}
#contact .text-danger {
  color: #cc0033;
  text-align: left;
}
#contact .btn-custom {
  margin: 30px 0;
  background: transparent;
  border: 2px solid #fff;
}
#contact .btn-custom:hover {
  color: #1f386e;
  background: #fff;
}
label {
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  float: left;
}
#contact .form-control {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #444;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
#contact .form-control:focus {
  border-color: #999;
  outline: 0;
  -webkit-box-shadow: transparent;
  box-shadow: transparent;
}
.form-control::-webkit-input-placeholder {
  color: #777;
}
.form-control:-moz-placeholder {
  color: #777;
}
.form-control::-moz-placeholder {
  color: #777;
}
.form-control:-ms-input-placeholder {
  color: #777;
}
#contact .contact-item {
  margin: 20px 0;
}
#contact .contact-item span {
  color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
  display: block;
}
#contact .contact-item i.fa {
  margin-right: 10px;
}
#contact .social {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 50px;
  margin-top: 50px;
  text-align: center;
}
#contact .social ul li {
  display: inline-block;
  margin: 0 20px;
}
#contact .social i.fa {
  font-size: 22px;
  width: 48px;
  height: 48px;
  padding: 12px 0;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 50%;
  transition: all 0.3s;
}
#contact .social i.fa:hover {
  color: #608dfd;
  background: #fff;
}


#contact_item{
  display: flex;
  justify-content: end;
}


.main_div{
  /* background-color: red; */
  align-items: center;
  justify-content: center;
  margin-left: 90px;
}
/* Footer Section*/
#footer {
  background: #f6f6f6;
  padding: 30px 0;
}
#footer p {
  color: #888;
  font-size: 14px;
}
#footer a {
  color: #608dfd;
}
#footer a:hover {
  border-bottom: 2px solid #608dfd;
}

@media (max-width: 768px) {
  #about img {
    margin: 50px 0;
  }
}