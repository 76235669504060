/* image css */
.div_main {
  width: 100%;
  height: 500px;
  /* background-image: url("mohandess1.png"); */
}

.img {
  height: 500px;
  width: 100%;
  background-size: auto;
  background-size: 100% 500px;
  background-repeat: no-repeat;
}

/* Engineer css */

.Engineer_div {
  height: 480px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.main_div {
  height: 400px;
  width: 50%;
  margin: 20px;
}

.main_div1 {
  height: 400px;
  width: 60%;
}


.sec_div {

  height: 40px;
  width: 100%;
  display: flex;

  align-items: center;
}

.text_main {
  background-color: #ebd61c;
  height: 3px;
  width: 50px;
  margin: 5px;
}

.text_color {
  color: black;
  font-size: 18px;
  font-weight: bold;
}

.color_text {
  color: #ebd61c;

}

.Image_div{
  border-radius: 10px;
}

.engineer-div {
  height: 360px;
  width: 100%;
}

.sec_div_engineer {
  width: 100%;
  height: 190px;
  display: flex;
  flex-direction: row;
}

.sec_div_engineer1 {
  width: 100%;
  height: 190px;
  display: flex;
  flex-direction: row;
}

.text-engineer {
  height: 190px;
  width: 50%;
}

.text-engineer1 {
  height: 190px;
  width: 50%;
}

.header_text {
  height: 50px;
  width: 100%;
  text-align: center;
}

.headre_div {
  height: 100px;
  width: 100%;
  padding: 5px;
  text-align: center;
}

.bold_text {
  font-size: 17px;
  margin: 5px;
  color: black;
}



/* connect css */

.connect_div {
  background-color: #ebd61c;
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.connect_logo {
  height: 100px;
  width: 25%;
}

.connect_text {
  height: 100px;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.connect_button {
  height: 100px;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bold {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.button {
  height: 40px;
  width: 200px;
  background-color: black;
  color: #ebd61c;
  border-radius: 20px;
}



/* project css */

.project {
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.project_div {
  width: 40%;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.project_div1 {
  height: 400px;
  width: 60%;
}

.project_img {
  border-radius: 10px;
}

.div_project {
  height: 50px;
  width: 70%;
  margin-top: 150px;
  align-items: center;
  display: flex;
}

.div_project1 {
  height: 40px;
  width: 50%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

.project_content {
  height: 40px;
  width: 30%;
  margin-left: 8px;
  margin-right: 8px;

}

.footer_color{
  color: white;
  font-size: 13px;
}

.footer_title{
  display: block;
}

.footer_text{
  color: rgb(212, 209, 209);
}


.heade-main_div{
  background-color:rgb(15, 9, 60);
  height: 91px;
}

/* slider_text_color */

.slider_text_color{
  font-size:16px;
  font-weight:lighter;
  padding:2px;
}



/* <heade/> */


.header-main_div{
  background-color:white;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Div_header{
  height: 50px;
  width: 40%;
  /* background-color: rgb(230, 208, 208); */
  
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

.Div_header1{
  height: 50px;
  width: 50%;
  /* background-color: red; */
  margin-right: 20px;
  margin-left:20px ;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

.header_text_size{
  height: 50px;
  width: 30%;
  /* background-color: white; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.header_text_size1{
  height: 50px;
  width: 20%;
  /* background-color: rgb(186, 53, 53); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.Text_size{
  color: black;
  font-size: 13px;
  font-weight: bold;
}

.header_img{
  height: 50px;
  width: 100%;
  background-color: #ebd61c;
  padding: 2px;
}

.header_icon{
  font-size: 25px;
  margin: 5px;
  color: black;
}

.header_icon1{
  font-size: 18px;
  margin: 5px;
  color: black;
}

.optionStyle{
  width: 30%;
  margin: 12px 0 0 35px;
}

.optionStyle :hover{
  width: 70%;
  background-color: rgb(194, 189, 184);
  text-transform: capitalize;
}

.showmenu {
  display : none;
}


@media (max-width: 992px){
  .showmenu {
    display : block;
  }
 
}